<template>
  <div>
      <ul class="preview-icon-list">
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-centos&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-centos"></em></div>
         <span class="preview-icon-name">centos</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-covid&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-covid"></em></div>
         <span class="preview-icon-name">covid</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-fedora&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-fedora"></em></div>
         <span class="preview-icon-name">fedora</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-hot-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-hot-fill"></em></div>
         <span class="preview-icon-name">hot-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-hot&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-hot"></em></div>
         <span class="preview-icon-name">hot</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-linux-server&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-linux-server"></em></div>
         <span class="preview-icon-name">linux-server</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-linux&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-linux"></em></div>
         <span class="preview-icon-name">linux</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-note-add-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-note-add-fill"></em></div>
         <span class="preview-icon-name">note-add-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-repeat-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-repeat-fill"></em></div>
         <span class="preview-icon-name">repeat-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tranx-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tranx-fill"></em></div>
         <span class="preview-icon-name">tranx-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-ubuntu&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-ubuntu"></em></div>
         <span class="preview-icon-name">ubuntu</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-virus&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-virus"></em></div>
         <span class="preview-icon-name">virus</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-b-chrome&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-b-chrome"></em></div>
         <span class="preview-icon-name">b-chrome</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-b-edge&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-b-edge"></em></div>
         <span class="preview-icon-name">b-edge</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-b-firefox&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-b-firefox"></em></div>
         <span class="preview-icon-name">b-firefox</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-b-ie&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-b-ie"></em></div>
         <span class="preview-icon-name">b-ie</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-b-opera&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-b-opera"></em></div>
         <span class="preview-icon-name">b-opera</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-b-safari&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-b-safari"></em></div>
         <span class="preview-icon-name">b-safari</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-b-si&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-b-si"></em></div>
         <span class="preview-icon-name">b-si</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-b-uc&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-b-uc"></em></div>
         <span class="preview-icon-name">b-uc</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-brick-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-brick-fill"></em></div>
         <span class="preview-icon-name">brick-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-brick&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-brick"></em></div>
         <span class="preview-icon-name">brick</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-col-3s&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-col-3s"></em></div>
         <span class="preview-icon-name">col-3s</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-col-4s&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-col-4s"></em></div>
         <span class="preview-icon-name">col-4s</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-col-2s&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-col-2s"></em></div>
         <span class="preview-icon-name">col-2s</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-comments&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-comments"></em></div>
         <span class="preview-icon-name">comments</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-dot-sq&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-dot-sq"></em></div>
         <span class="preview-icon-name">dot-sq</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-dot&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-dot"></em></div>
         <span class="preview-icon-name">dot</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-footer&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-footer"></em></div>
         <span class="preview-icon-name">footer</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-header&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-header"></em></div>
         <span class="preview-icon-name">header</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-heading&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-heading"></em></div>
         <span class="preview-icon-name">heading</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-layout-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-layout-alt-fill"></em></div>
         <span class="preview-icon-name">layout-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-layout-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-layout-alt"></em></div>
         <span class="preview-icon-name">layout-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-layout-fill1&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-layout-fill1"></em></div>
         <span class="preview-icon-name">layout-fill1</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-layout1&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-layout1"></em></div>
         <span class="preview-icon-name">layout1</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-list-index-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-list-index-fill"></em></div>
         <span class="preview-icon-name">list-index-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-list-index&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-list-index"></em></div>
         <span class="preview-icon-name">list-index</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-list-thumb-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-list-thumb-alt-fill"></em></div>
         <span class="preview-icon-name">list-thumb-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-list-thumb-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-list-thumb-alt"></em></div>
         <span class="preview-icon-name">list-thumb-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-list-thumb-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-list-thumb-fill"></em></div>
         <span class="preview-icon-name">list-thumb-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-list-thumb&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-list-thumb"></em></div>
         <span class="preview-icon-name">list-thumb</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-masonry-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-masonry-fill"></em></div>
         <span class="preview-icon-name">masonry-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-masonry&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-masonry"></em></div>
         <span class="preview-icon-name">masonry</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-menu-circled&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-menu-circled"></em></div>
         <span class="preview-icon-name">menu-circled</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-menu-squared&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-menu-squared"></em></div>
         <span class="preview-icon-name">menu-squared</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-notice&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-notice"></em></div>
         <span class="preview-icon-name">notice</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pen2&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pen2"></em></div>
         <span class="preview-icon-name">pen2</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-propert-blank&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-propert-blank"></em></div>
         <span class="preview-icon-name">propert-blank</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-property-add&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-property-add"></em></div>
         <span class="preview-icon-name">property-add</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-property-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-property-alt"></em></div>
         <span class="preview-icon-name">property-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-property-remove&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-property-remove"></em></div>
         <span class="preview-icon-name">property-remove</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-property&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-property"></em></div>
         <span class="preview-icon-name">property</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-puzzle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-puzzle-fill"></em></div>
         <span class="preview-icon-name">puzzle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-puzzle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-puzzle"></em></div>
         <span class="preview-icon-name">puzzle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-quote-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-quote-left"></em></div>
         <span class="preview-icon-name">quote-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-quote-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-quote-right"></em></div>
         <span class="preview-icon-name">quote-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-row-mix&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-row-mix"></em></div>
         <span class="preview-icon-name">row-mix</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-row-view1&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-row-view1"></em></div>
         <span class="preview-icon-name">row-view1</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sidebar-r&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sidebar-r"></em></div>
         <span class="preview-icon-name">sidebar-r</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-text2&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-text2"></em></div>
         <span class="preview-icon-name">text2</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tile-thumb-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tile-thumb-fill"></em></div>
         <span class="preview-icon-name">tile-thumb-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tile-thumb&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tile-thumb"></em></div>
         <span class="preview-icon-name">tile-thumb</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-col-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-col-fill"></em></div>
         <span class="preview-icon-name">view-col-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-col-sq&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-col-sq"></em></div>
         <span class="preview-icon-name">view-col-sq</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-col&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-col"></em></div>
         <span class="preview-icon-name">view-col</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-col2&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-col2"></em></div>
         <span class="preview-icon-name">view-col2</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-col3&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-col3"></em></div>
         <span class="preview-icon-name">view-col3</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-cols-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-cols-fill"></em></div>
         <span class="preview-icon-name">view-cols-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-cols-sq&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-cols-sq"></em></div>
         <span class="preview-icon-name">view-cols-sq</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-cols&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-cols"></em></div>
         <span class="preview-icon-name">view-cols</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-grid-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-grid-fill"></em></div>
         <span class="preview-icon-name">view-grid-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-grid-sq&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-grid-sq"></em></div>
         <span class="preview-icon-name">view-grid-sq</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-grid-wd&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-grid-wd"></em></div>
         <span class="preview-icon-name">view-grid-wd</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-grid&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-grid"></em></div>
         <span class="preview-icon-name">view-grid</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-grid2-wd&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-grid2-wd"></em></div>
         <span class="preview-icon-name">view-grid2-wd</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-grid3-wd&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-grid3-wd"></em></div>
         <span class="preview-icon-name">view-grid3-wd</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-group-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-group-fill"></em></div>
         <span class="preview-icon-name">view-group-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-group-wd&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-group-wd"></em></div>
         <span class="preview-icon-name">view-group-wd</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-list-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-list-fill"></em></div>
         <span class="preview-icon-name">view-list-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-list-sq&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-list-sq"></em></div>
         <span class="preview-icon-name">view-list-sq</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-list-wd&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-list-wd"></em></div>
         <span class="preview-icon-name">view-list-wd</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-list&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-list"></em></div>
         <span class="preview-icon-name">view-list</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-panel-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-panel-fill"></em></div>
         <span class="preview-icon-name">view-panel-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-panel-sq&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-panel-sq"></em></div>
         <span class="preview-icon-name">view-panel-sq</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-panel&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-panel"></em></div>
         <span class="preview-icon-name">view-panel</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-row-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-row-fill"></em></div>
         <span class="preview-icon-name">view-row-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-row-sq&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-row-sq"></em></div>
         <span class="preview-icon-name">view-row-sq</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-row-wd&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-row-wd"></em></div>
         <span class="preview-icon-name">view-row-wd</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-row&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-row"></em></div>
         <span class="preview-icon-name">view-row</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-x1&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-x1"></em></div>
         <span class="preview-icon-name">view-x1</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-x2&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-x2"></em></div>
         <span class="preview-icon-name">view-x2</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-x3&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-x3"></em></div>
         <span class="preview-icon-name">view-x3</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-x4&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-x4"></em></div>
         <span class="preview-icon-name">view-x4</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-x5&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-x5"></em></div>
         <span class="preview-icon-name">view-x5</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-x6&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-x6"></em></div>
         <span class="preview-icon-name">view-x6</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-view-x7&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-view-x7"></em></div>
         <span class="preview-icon-name">view-x7</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-dashlite&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-dashlite"></em></div>
         <span class="preview-icon-name">dashlite</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-dashlite-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-dashlite-circle"></em></div>
         <span class="preview-icon-name">dashlite-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-dashlite-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-dashlite-alt"></em></div>
         <span class="preview-icon-name">dashlite-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-master-card&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-master-card"></em></div>
         <span class="preview-icon-name">master-card</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-paypal&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-paypal"></em></div>
         <span class="preview-icon-name">paypal</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-visa-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-visa-alt"></em></div>
         <span class="preview-icon-name">visa-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-coin-eur&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-coin-eur"></em></div>
         <span class="preview-icon-name">coin-eur</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-coin-gbp&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-coin-gbp"></em></div>
         <span class="preview-icon-name">coin-gbp</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-ada-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-ada-alt"></em></div>
         <span class="preview-icon-name">sign-ada-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-bch-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-bch-alt"></em></div>
         <span class="preview-icon-name">sign-bch-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-bgp-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-bgp-alt"></em></div>
         <span class="preview-icon-name">sign-bgp-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-bnb-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-bnb-alt"></em></div>
         <span class="preview-icon-name">sign-bnb-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-brl-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-brl-alt"></em></div>
         <span class="preview-icon-name">sign-brl-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-btc-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-btc-alt"></em></div>
         <span class="preview-icon-name">sign-btc-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-cc-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-cc-alt"></em></div>
         <span class="preview-icon-name">sign-cc-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-cc-alt2&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-cc-alt2"></em></div>
         <span class="preview-icon-name">sign-cc-alt2</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-chf-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-chf-alt"></em></div>
         <span class="preview-icon-name">sign-chf-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-cny-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-cny-alt"></em></div>
         <span class="preview-icon-name">sign-cny-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-czk-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-czk-alt"></em></div>
         <span class="preview-icon-name">sign-czk-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-dash-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-dash-alt"></em></div>
         <span class="preview-icon-name">sign-dash-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-dkk-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-dkk-alt"></em></div>
         <span class="preview-icon-name">sign-dkk-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-eos-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-eos-alt"></em></div>
         <span class="preview-icon-name">sign-eos-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-eth-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-eth-alt"></em></div>
         <span class="preview-icon-name">sign-eth-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-eur-alt2&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-eur-alt2"></em></div>
         <span class="preview-icon-name">sign-eur-alt2</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-euro-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-euro-alt"></em></div>
         <span class="preview-icon-name">sign-euro-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-gbp-alt2&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-gbp-alt2"></em></div>
         <span class="preview-icon-name">sign-gbp-alt2</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-hkd-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-hkd-alt"></em></div>
         <span class="preview-icon-name">sign-hkd-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-idr-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-idr-alt"></em></div>
         <span class="preview-icon-name">sign-idr-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-inr-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-inr-alt"></em></div>
         <span class="preview-icon-name">sign-inr-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-jpy-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-jpy-alt"></em></div>
         <span class="preview-icon-name">sign-jpy-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-kr-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-kr-alt"></em></div>
         <span class="preview-icon-name">sign-kr-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-ltc-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-ltc-alt"></em></div>
         <span class="preview-icon-name">sign-ltc-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-ltc&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-ltc"></em></div>
         <span class="preview-icon-name">sign-ltc</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-mxn-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-mxn-alt"></em></div>
         <span class="preview-icon-name">sign-mxn-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-mxr-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-mxr-alt"></em></div>
         <span class="preview-icon-name">sign-mxr-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-myr-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-myr-alt"></em></div>
         <span class="preview-icon-name">sign-myr-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-paypal-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-paypal-alt"></em></div>
         <span class="preview-icon-name">sign-paypal-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-paypal-full&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-paypal-full"></em></div>
         <span class="preview-icon-name">sign-paypal-full</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-php-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-php-alt"></em></div>
         <span class="preview-icon-name">sign-php-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-pln-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-pln-alt"></em></div>
         <span class="preview-icon-name">sign-pln-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-rub-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-rub-alt"></em></div>
         <span class="preview-icon-name">sign-rub-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-sek-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-sek-alt"></em></div>
         <span class="preview-icon-name">sign-sek-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-sgd-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-sgd-alt"></em></div>
         <span class="preview-icon-name">sign-sgd-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-kobo-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-kobo-alt"></em></div>
         <span class="preview-icon-name">sign-kobo-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-steem-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-steem-alt"></em></div>
         <span class="preview-icon-name">sign-steem-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-steller-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-steller-alt"></em></div>
         <span class="preview-icon-name">sign-steller-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-stripe-fulll&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-stripe-fulll"></em></div>
         <span class="preview-icon-name">sign-stripe-fulll</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-thb-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-thb-alt"></em></div>
         <span class="preview-icon-name">sign-thb-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-trx-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-trx-alt"></em></div>
         <span class="preview-icon-name">sign-trx-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-try-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-try-alt"></em></div>
         <span class="preview-icon-name">sign-try-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-usd-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-usd-alt"></em></div>
         <span class="preview-icon-name">sign-usd-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-usd-alt2&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-usd-alt2"></em></div>
         <span class="preview-icon-name">sign-usd-alt2</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-usdc-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-usdc-alt"></em></div>
         <span class="preview-icon-name">sign-usdc-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-usdt-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-usdt-alt"></em></div>
         <span class="preview-icon-name">sign-usdt-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-visa-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-visa-alt"></em></div>
         <span class="preview-icon-name">sign-visa-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-vnd-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-vnd-alt"></em></div>
         <span class="preview-icon-name">sign-vnd-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-waves-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-waves-alt"></em></div>
         <span class="preview-icon-name">sign-waves-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-xem-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-xem-alt"></em></div>
         <span class="preview-icon-name">sign-xem-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-xrp-new-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-xrp-new-alt"></em></div>
         <span class="preview-icon-name">sign-xrp-new-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-xrp-old-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-xrp-old-alt"></em></div>
         <span class="preview-icon-name">sign-xrp-old-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-zcash-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-zcash-alt"></em></div>
         <span class="preview-icon-name">sign-zcash-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-left"></em></div>
         <span class="preview-icon-name">chevron-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-right"></em></div>
         <span class="preview-icon-name">chevron-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-up"></em></div>
         <span class="preview-icon-name">chevron-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-down"></em></div>
         <span class="preview-icon-name">chevron-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-left-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-left-round"></em></div>
         <span class="preview-icon-name">chevron-left-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-right-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-right-round"></em></div>
         <span class="preview-icon-name">chevron-right-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-up-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-up-round"></em></div>
         <span class="preview-icon-name">chevron-up-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-down-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-down-round"></em></div>
         <span class="preview-icon-name">chevron-down-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-left-round-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-left-round-fill"></em></div>
         <span class="preview-icon-name">chevron-left-round-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-right-round-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-right-round-fill"></em></div>
         <span class="preview-icon-name">chevron-right-round-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-up-round-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-up-round-fill"></em></div>
         <span class="preview-icon-name">chevron-up-round-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-down-round-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-down-round-fill"></em></div>
         <span class="preview-icon-name">chevron-down-round-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-left-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-left-c"></em></div>
         <span class="preview-icon-name">chevron-left-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-right-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-right-c"></em></div>
         <span class="preview-icon-name">chevron-right-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-up-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-up-c"></em></div>
         <span class="preview-icon-name">chevron-up-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-down-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-down-c"></em></div>
         <span class="preview-icon-name">chevron-down-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-left-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-left-fill-c"></em></div>
         <span class="preview-icon-name">chevron-left-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-right-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-right-fill-c"></em></div>
         <span class="preview-icon-name">chevron-right-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-up-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-up-fill-c"></em></div>
         <span class="preview-icon-name">chevron-up-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-down-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-down-fill-c"></em></div>
         <span class="preview-icon-name">chevron-down-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-left-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-left-circle"></em></div>
         <span class="preview-icon-name">chevron-left-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-right-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-right-circle"></em></div>
         <span class="preview-icon-name">chevron-right-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-up-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-up-circle"></em></div>
         <span class="preview-icon-name">chevron-up-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-down-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-down-circle"></em></div>
         <span class="preview-icon-name">chevron-down-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-left-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-left-circle-fill"></em></div>
         <span class="preview-icon-name">chevron-left-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-right-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-right-circle-fill"></em></div>
         <span class="preview-icon-name">chevron-right-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-up-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-up-circle-fill"></em></div>
         <span class="preview-icon-name">chevron-up-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevron-down-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevron-down-circle-fill"></em></div>
         <span class="preview-icon-name">chevron-down-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-caret-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-caret-left"></em></div>
         <span class="preview-icon-name">caret-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-caret-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-caret-right"></em></div>
         <span class="preview-icon-name">caret-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-caret-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-caret-up"></em></div>
         <span class="preview-icon-name">caret-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-caret-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-caret-down"></em></div>
         <span class="preview-icon-name">caret-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-caret-left-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-caret-left-fill"></em></div>
         <span class="preview-icon-name">caret-left-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-caret-right-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-caret-right-fill"></em></div>
         <span class="preview-icon-name">caret-right-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-caret-up-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-caret-up-fill"></em></div>
         <span class="preview-icon-name">caret-up-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-caret-down-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-caret-down-fill"></em></div>
         <span class="preview-icon-name">caret-down-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sort&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sort"></em></div>
         <span class="preview-icon-name">sort</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sort-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sort-up"></em></div>
         <span class="preview-icon-name">sort-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sort-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sort-down"></em></div>
         <span class="preview-icon-name">sort-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sort-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sort-fill"></em></div>
         <span class="preview-icon-name">sort-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sort-up-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sort-up-fill"></em></div>
         <span class="preview-icon-name">sort-up-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sort-down-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sort-down-fill"></em></div>
         <span class="preview-icon-name">sort-down-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sort-v&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sort-v"></em></div>
         <span class="preview-icon-name">sort-v</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-swap-v&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-swap-v"></em></div>
         <span class="preview-icon-name">swap-v</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-swap&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-swap"></em></div>
         <span class="preview-icon-name">swap</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-left-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-left-round"></em></div>
         <span class="preview-icon-name">arrow-left-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-right-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-right-round"></em></div>
         <span class="preview-icon-name">arrow-right-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-up-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-up-round"></em></div>
         <span class="preview-icon-name">arrow-up-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-down-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-down-round"></em></div>
         <span class="preview-icon-name">arrow-down-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-left-round-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-left-round-fill"></em></div>
         <span class="preview-icon-name">arrow-left-round-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-right-round-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-right-round-fill"></em></div>
         <span class="preview-icon-name">arrow-right-round-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-up-round-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-up-round-fill"></em></div>
         <span class="preview-icon-name">arrow-up-round-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-down-round-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-down-round-fill"></em></div>
         <span class="preview-icon-name">arrow-down-round-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-left-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-left-c"></em></div>
         <span class="preview-icon-name">arrow-left-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-right-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-right-c"></em></div>
         <span class="preview-icon-name">arrow-right-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-up-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-up-c"></em></div>
         <span class="preview-icon-name">arrow-up-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-down-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-down-c"></em></div>
         <span class="preview-icon-name">arrow-down-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-left-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-left-fill-c"></em></div>
         <span class="preview-icon-name">arrow-left-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-right-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-right-fill-c"></em></div>
         <span class="preview-icon-name">arrow-right-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-up-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-up-fill-c"></em></div>
         <span class="preview-icon-name">arrow-up-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-down-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-down-fill-c"></em></div>
         <span class="preview-icon-name">arrow-down-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-left-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-left-circle"></em></div>
         <span class="preview-icon-name">arrow-left-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-right-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-right-circle"></em></div>
         <span class="preview-icon-name">arrow-right-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-up-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-up-circle"></em></div>
         <span class="preview-icon-name">arrow-up-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-down-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-down-circle"></em></div>
         <span class="preview-icon-name">arrow-down-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-left-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-left-circle-fill"></em></div>
         <span class="preview-icon-name">arrow-left-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-up-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-up-circle-fill"></em></div>
         <span class="preview-icon-name">arrow-up-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-down-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-down-circle-fill"></em></div>
         <span class="preview-icon-name">arrow-down-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-right-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-right-circle-fill"></em></div>
         <span class="preview-icon-name">arrow-right-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevrons-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevrons-left"></em></div>
         <span class="preview-icon-name">chevrons-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevrons-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevrons-right"></em></div>
         <span class="preview-icon-name">chevrons-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevrons-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevrons-up"></em></div>
         <span class="preview-icon-name">chevrons-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chevrons-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chevrons-down"></em></div>
         <span class="preview-icon-name">chevrons-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-first&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-first"></em></div>
         <span class="preview-icon-name">first</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-last&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-last"></em></div>
         <span class="preview-icon-name">last</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-back-ios&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-back-ios"></em></div>
         <span class="preview-icon-name">back-ios</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-forward-ios&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-forward-ios"></em></div>
         <span class="preview-icon-name">forward-ios</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-upword-ios&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-upword-ios"></em></div>
         <span class="preview-icon-name">upword-ios</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-downward-ios&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-downward-ios"></em></div>
         <span class="preview-icon-name">downward-ios</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-back-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-back-alt"></em></div>
         <span class="preview-icon-name">back-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-forward-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-forward-alt"></em></div>
         <span class="preview-icon-name">forward-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-upword-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-upword-alt"></em></div>
         <span class="preview-icon-name">upword-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-downward-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-downward-alt"></em></div>
         <span class="preview-icon-name">downward-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-back-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-back-alt-fill"></em></div>
         <span class="preview-icon-name">back-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-forward-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-forward-alt-fill"></em></div>
         <span class="preview-icon-name">forward-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-upword-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-upword-alt-fill"></em></div>
         <span class="preview-icon-name">upword-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-downward-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-downward-alt-fill"></em></div>
         <span class="preview-icon-name">downward-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-long-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-long-left"></em></div>
         <span class="preview-icon-name">arrow-long-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-long-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-long-right"></em></div>
         <span class="preview-icon-name">arrow-long-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-long-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-long-up"></em></div>
         <span class="preview-icon-name">arrow-long-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-long-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-long-down"></em></div>
         <span class="preview-icon-name">arrow-long-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-left"></em></div>
         <span class="preview-icon-name">arrow-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-right"></em></div>
         <span class="preview-icon-name">arrow-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-up"></em></div>
         <span class="preview-icon-name">arrow-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-down"></em></div>
         <span class="preview-icon-name">arrow-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-up-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-up-left"></em></div>
         <span class="preview-icon-name">arrow-up-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-up-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-up-right"></em></div>
         <span class="preview-icon-name">arrow-up-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-down-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-down-left"></em></div>
         <span class="preview-icon-name">arrow-down-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-down-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-down-right"></em></div>
         <span class="preview-icon-name">arrow-down-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-to-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-to-left"></em></div>
         <span class="preview-icon-name">arrow-to-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-to-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-to-right"></em></div>
         <span class="preview-icon-name">arrow-to-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-to-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-to-up"></em></div>
         <span class="preview-icon-name">arrow-to-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-to-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-to-down"></em></div>
         <span class="preview-icon-name">arrow-to-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-from-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-from-left"></em></div>
         <span class="preview-icon-name">arrow-from-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-from-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-from-right"></em></div>
         <span class="preview-icon-name">arrow-from-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-from-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-from-up"></em></div>
         <span class="preview-icon-name">arrow-from-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-arrow-from-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-arrow-from-down"></em></div>
         <span class="preview-icon-name">arrow-from-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-curve-down-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-curve-down-left"></em></div>
         <span class="preview-icon-name">curve-down-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-curve-up-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-curve-up-right"></em></div>
         <span class="preview-icon-name">curve-up-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-curve-up-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-curve-up-left"></em></div>
         <span class="preview-icon-name">curve-up-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-curve-down-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-curve-down-right"></em></div>
         <span class="preview-icon-name">curve-down-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-curve-left-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-curve-left-up"></em></div>
         <span class="preview-icon-name">curve-left-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-curve-right-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-curve-right-up"></em></div>
         <span class="preview-icon-name">curve-right-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-curve-left-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-curve-left-down"></em></div>
         <span class="preview-icon-name">curve-left-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-curve-right-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-curve-right-down"></em></div>
         <span class="preview-icon-name">curve-right-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-back-arrow&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-back-arrow"></em></div>
         <span class="preview-icon-name">back-arrow</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-forward-arrow&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-forward-arrow"></em></div>
         <span class="preview-icon-name">forward-arrow</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-back-arrow-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-back-arrow-fill"></em></div>
         <span class="preview-icon-name">back-arrow-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-forward-arrow-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-forward-arrow-fill"></em></div>
         <span class="preview-icon-name">forward-arrow-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-navigate&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-navigate"></em></div>
         <span class="preview-icon-name">navigate</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-navigate-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-navigate-up"></em></div>
         <span class="preview-icon-name">navigate-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-navigate-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-navigate-fill"></em></div>
         <span class="preview-icon-name">navigate-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-navigate-up-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-navigate-up-fill"></em></div>
         <span class="preview-icon-name">navigate-up-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-send&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-send"></em></div>
         <span class="preview-icon-name">send</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-send-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-send-alt"></em></div>
         <span class="preview-icon-name">send-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-unfold-less&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-unfold-less"></em></div>
         <span class="preview-icon-name">unfold-less</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-unfold-more&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-unfold-more"></em></div>
         <span class="preview-icon-name">unfold-more</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-exchange-v&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-exchange-v"></em></div>
         <span class="preview-icon-name">exchange-v</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-exchange&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-exchange"></em></div>
         <span class="preview-icon-name">exchange</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-expand&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-expand"></em></div>
         <span class="preview-icon-name">expand</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-shrink&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-shrink"></em></div>
         <span class="preview-icon-name">shrink</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-focus&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-focus"></em></div>
         <span class="preview-icon-name">focus</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-maximize&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-maximize"></em></div>
         <span class="preview-icon-name">maximize</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-minimize&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-minimize"></em></div>
         <span class="preview-icon-name">minimize</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-maximize-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-maximize-alt"></em></div>
         <span class="preview-icon-name">maximize-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-minimize-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-minimize-alt"></em></div>
         <span class="preview-icon-name">minimize-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-shuffle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-shuffle"></em></div>
         <span class="preview-icon-name">shuffle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cross-sm&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cross-sm"></em></div>
         <span class="preview-icon-name">cross-sm</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cross&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cross"></em></div>
         <span class="preview-icon-name">cross</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cross-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cross-round"></em></div>
         <span class="preview-icon-name">cross-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cross-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cross-circle"></em></div>
         <span class="preview-icon-name">cross-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cross-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cross-c"></em></div>
         <span class="preview-icon-name">cross-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cross-round-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cross-round-fill"></em></div>
         <span class="preview-icon-name">cross-round-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cross-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cross-circle-fill"></em></div>
         <span class="preview-icon-name">cross-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cross-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cross-fill-c"></em></div>
         <span class="preview-icon-name">cross-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-na&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-na"></em></div>
         <span class="preview-icon-name">na</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-check&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-check"></em></div>
         <span class="preview-icon-name">check</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-check-thick&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-check-thick"></em></div>
         <span class="preview-icon-name">check-thick</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-done&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-done"></em></div>
         <span class="preview-icon-name">done</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-check-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-check-round"></em></div>
         <span class="preview-icon-name">check-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-check-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-check-circle"></em></div>
         <span class="preview-icon-name">check-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-check-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-check-c"></em></div>
         <span class="preview-icon-name">check-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-check-round-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-check-round-fill"></em></div>
         <span class="preview-icon-name">check-round-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-check-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-check-circle-fill"></em></div>
         <span class="preview-icon-name">check-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-check-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-check-fill-c"></em></div>
         <span class="preview-icon-name">check-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-check-circle-cut&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-check-circle-cut"></em></div>
         <span class="preview-icon-name">check-circle-cut</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-check-round-cut&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-check-round-cut"></em></div>
         <span class="preview-icon-name">check-round-cut</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bullet&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bullet"></em></div>
         <span class="preview-icon-name">bullet</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-circle"></em></div>
         <span class="preview-icon-name">circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-square&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-square"></em></div>
         <span class="preview-icon-name">square</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-square-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-square-c"></em></div>
         <span class="preview-icon-name">square-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bullet-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bullet-fill"></em></div>
         <span class="preview-icon-name">bullet-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-circle-fill"></em></div>
         <span class="preview-icon-name">circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-square-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-square-fill"></em></div>
         <span class="preview-icon-name">square-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-square-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-square-fill-c"></em></div>
         <span class="preview-icon-name">square-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-plus-sm&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-plus-sm"></em></div>
         <span class="preview-icon-name">plus-sm</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-minus-sm&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-minus-sm"></em></div>
         <span class="preview-icon-name">minus-sm</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-plus&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-plus"></em></div>
         <span class="preview-icon-name">plus</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-minus&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-minus"></em></div>
         <span class="preview-icon-name">minus</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-plus-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-plus-round"></em></div>
         <span class="preview-icon-name">plus-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-minus-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-minus-round"></em></div>
         <span class="preview-icon-name">minus-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-plus-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-plus-circle"></em></div>
         <span class="preview-icon-name">plus-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-minus-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-minus-circle"></em></div>
         <span class="preview-icon-name">minus-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-plus-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-plus-c"></em></div>
         <span class="preview-icon-name">plus-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-minus-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-minus-c"></em></div>
         <span class="preview-icon-name">minus-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-plus-round-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-plus-round-fill"></em></div>
         <span class="preview-icon-name">plus-round-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-plus-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-plus-circle-fill"></em></div>
         <span class="preview-icon-name">plus-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-minus-round-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-minus-round-fill"></em></div>
         <span class="preview-icon-name">minus-round-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-minus-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-minus-circle-fill"></em></div>
         <span class="preview-icon-name">minus-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-plus-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-plus-fill-c"></em></div>
         <span class="preview-icon-name">plus-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-minus-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-minus-fill-c"></em></div>
         <span class="preview-icon-name">minus-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-plus-medi&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-plus-medi"></em></div>
         <span class="preview-icon-name">plus-medi</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-plus-medi-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-plus-medi-fill"></em></div>
         <span class="preview-icon-name">plus-medi-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-equal-sm&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-equal-sm"></em></div>
         <span class="preview-icon-name">equal-sm</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-equal&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-equal"></em></div>
         <span class="preview-icon-name">equal</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-calc&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-calc"></em></div>
         <span class="preview-icon-name">calc</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-search&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-search"></em></div>
         <span class="preview-icon-name">search</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-zoom-out&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-zoom-out"></em></div>
         <span class="preview-icon-name">zoom-out</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-zoom-in&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-zoom-in"></em></div>
         <span class="preview-icon-name">zoom-in</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-play&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-play"></em></div>
         <span class="preview-icon-name">play</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-play-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-play-fill"></em></div>
         <span class="preview-icon-name">play-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-play-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-play-circle"></em></div>
         <span class="preview-icon-name">play-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-play-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-play-circle-fill"></em></div>
         <span class="preview-icon-name">play-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pause&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pause"></em></div>
         <span class="preview-icon-name">pause</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pause-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pause-fill"></em></div>
         <span class="preview-icon-name">pause-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pause-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pause-circle"></em></div>
         <span class="preview-icon-name">pause-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pause-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pause-circle-fill"></em></div>
         <span class="preview-icon-name">pause-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-stop&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-stop"></em></div>
         <span class="preview-icon-name">stop</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-stop-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-stop-fill"></em></div>
         <span class="preview-icon-name">stop-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-stop-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-stop-circle"></em></div>
         <span class="preview-icon-name">stop-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-stop-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-stop-circle-fill"></em></div>
         <span class="preview-icon-name">stop-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-rewind&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-rewind"></em></div>
         <span class="preview-icon-name">rewind</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-forward&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-forward"></em></div>
         <span class="preview-icon-name">forward</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-rewind-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-rewind-fill"></em></div>
         <span class="preview-icon-name">rewind-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-forward-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-forward-fill"></em></div>
         <span class="preview-icon-name">forward-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-step-back&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-step-back"></em></div>
         <span class="preview-icon-name">step-back</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-step-forward&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-step-forward"></em></div>
         <span class="preview-icon-name">step-forward</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-vol-off&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-vol-off"></em></div>
         <span class="preview-icon-name">vol-off</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-vol-no&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-vol-no"></em></div>
         <span class="preview-icon-name">vol-no</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-vol-half&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-vol-half"></em></div>
         <span class="preview-icon-name">vol-half</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-vol&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-vol"></em></div>
         <span class="preview-icon-name">vol</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-mic&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-mic"></em></div>
         <span class="preview-icon-name">mic</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-mic-off&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-mic-off"></em></div>
         <span class="preview-icon-name">mic-off</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-video&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-video"></em></div>
         <span class="preview-icon-name">video</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-video-off&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-video-off"></em></div>
         <span class="preview-icon-name">video-off</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-video-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-video-fill"></em></div>
         <span class="preview-icon-name">video-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-loader&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-loader"></em></div>
         <span class="preview-icon-name">loader</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-power&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-power"></em></div>
         <span class="preview-icon-name">power</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-signout&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-signout"></em></div>
         <span class="preview-icon-name">signout</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-signin&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-signin"></em></div>
         <span class="preview-icon-name">signin</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-upload&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-upload"></em></div>
         <span class="preview-icon-name">upload</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-download&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-download"></em></div>
         <span class="preview-icon-name">download</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-alert-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-alert-circle"></em></div>
         <span class="preview-icon-name">alert-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-alert&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-alert"></em></div>
         <span class="preview-icon-name">alert</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-caution&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-caution"></em></div>
         <span class="preview-icon-name">caution</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-report&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-report"></em></div>
         <span class="preview-icon-name">report</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-alert-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-alert-c"></em></div>
         <span class="preview-icon-name">alert-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-alert-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-alert-circle-fill"></em></div>
         <span class="preview-icon-name">alert-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-alert-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-alert-fill"></em></div>
         <span class="preview-icon-name">alert-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-caution-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-caution-fill"></em></div>
         <span class="preview-icon-name">caution-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-report-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-report-fill"></em></div>
         <span class="preview-icon-name">report-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-alert-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-alert-fill-c"></em></div>
         <span class="preview-icon-name">alert-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-info-i&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-info-i"></em></div>
         <span class="preview-icon-name">info-i</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-info&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-info"></em></div>
         <span class="preview-icon-name">info</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-info-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-info-fill"></em></div>
         <span class="preview-icon-name">info-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-help&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-help"></em></div>
         <span class="preview-icon-name">help</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-help-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-help-fill"></em></div>
         <span class="preview-icon-name">help-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-archived&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-archived"></em></div>
         <span class="preview-icon-name">archived</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-archive&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-archive"></em></div>
         <span class="preview-icon-name">archive</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-unarchive&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-unarchive"></em></div>
         <span class="preview-icon-name">unarchive</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-archived-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-archived-fill"></em></div>
         <span class="preview-icon-name">archived-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-archive-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-archive-fill"></em></div>
         <span class="preview-icon-name">archive-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-unarchive-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-unarchive-fill"></em></div>
         <span class="preview-icon-name">unarchive-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bag&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bag"></em></div>
         <span class="preview-icon-name">bag</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bag-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bag-fill"></em></div>
         <span class="preview-icon-name">bag-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bell&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bell"></em></div>
         <span class="preview-icon-name">bell</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bell-off&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bell-off"></em></div>
         <span class="preview-icon-name">bell-off</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bell-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bell-fill"></em></div>
         <span class="preview-icon-name">bell-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bell-off-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bell-off-fill"></em></div>
         <span class="preview-icon-name">bell-off-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-wifi&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-wifi"></em></div>
         <span class="preview-icon-name">wifi</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-wifi-off&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-wifi-off"></em></div>
         <span class="preview-icon-name">wifi-off</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-live&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-live"></em></div>
         <span class="preview-icon-name">live</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-signal&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-signal"></em></div>
         <span class="preview-icon-name">signal</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bluetooth&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bluetooth"></em></div>
         <span class="preview-icon-name">bluetooth</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-blank-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-blank-alt"></em></div>
         <span class="preview-icon-name">blank-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-blank&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-blank"></em></div>
         <span class="preview-icon-name">blank</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-blankf-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-blankf-fill"></em></div>
         <span class="preview-icon-name">blankf-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-block-over&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-block-over"></em></div>
         <span class="preview-icon-name">block-over</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-book-read&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-book-read"></em></div>
         <span class="preview-icon-name">book-read</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-book&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-book"></em></div>
         <span class="preview-icon-name">book</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-book-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-book-fill"></em></div>
         <span class="preview-icon-name">book-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bulb-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bulb-fill"></em></div>
         <span class="preview-icon-name">bulb-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bulb&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bulb"></em></div>
         <span class="preview-icon-name">bulb</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-calendar-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-calendar-alt-fill"></em></div>
         <span class="preview-icon-name">calendar-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-calendar-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-calendar-alt"></em></div>
         <span class="preview-icon-name">calendar-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-calendar-booking-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-calendar-booking-fill"></em></div>
         <span class="preview-icon-name">calendar-booking-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-calendar-booking&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-calendar-booking"></em></div>
         <span class="preview-icon-name">calendar-booking</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-calendar-check-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-calendar-check-fill"></em></div>
         <span class="preview-icon-name">calendar-check-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-calendar-check&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-calendar-check"></em></div>
         <span class="preview-icon-name">calendar-check</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-calendar-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-calendar-fill"></em></div>
         <span class="preview-icon-name">calendar-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-calendar&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-calendar"></em></div>
         <span class="preview-icon-name">calendar</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-calender-date-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-calender-date-fill"></em></div>
         <span class="preview-icon-name">calender-date-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-calender-date&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-calender-date"></em></div>
         <span class="preview-icon-name">calender-date</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-call&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-call"></em></div>
         <span class="preview-icon-name">call</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-call-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-call-alt"></em></div>
         <span class="preview-icon-name">call-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-call-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-call-alt-fill"></em></div>
         <span class="preview-icon-name">call-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-call-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-call-fill"></em></div>
         <span class="preview-icon-name">call-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-camera-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-camera-fill"></em></div>
         <span class="preview-icon-name">camera-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-camera&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-camera"></em></div>
         <span class="preview-icon-name">camera</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-capsule&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-capsule"></em></div>
         <span class="preview-icon-name">capsule</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-capsule-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-capsule-fill"></em></div>
         <span class="preview-icon-name">capsule-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cards&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cards"></em></div>
         <span class="preview-icon-name">cards</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cards-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cards-fill"></em></div>
         <span class="preview-icon-name">cards-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cart&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cart"></em></div>
         <span class="preview-icon-name">cart</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cart-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cart-fill"></em></div>
         <span class="preview-icon-name">cart-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc"></em></div>
         <span class="preview-icon-name">cc</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-alt"></em></div>
         <span class="preview-icon-name">cc-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-alt2&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-alt2"></em></div>
         <span class="preview-icon-name">cc-alt2</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-secure&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-secure"></em></div>
         <span class="preview-icon-name">cc-secure</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-new&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-new"></em></div>
         <span class="preview-icon-name">cc-new</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-off&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-off"></em></div>
         <span class="preview-icon-name">cc-off</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-fill"></em></div>
         <span class="preview-icon-name">cc-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-alt-fill"></em></div>
         <span class="preview-icon-name">cc-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-alt2-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-alt2-fill"></em></div>
         <span class="preview-icon-name">cc-alt2-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-secure-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-secure-fill"></em></div>
         <span class="preview-icon-name">cc-secure-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-msg-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-msg-circle"></em></div>
         <span class="preview-icon-name">msg-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chat-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chat-circle"></em></div>
         <span class="preview-icon-name">chat-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-msg&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-msg"></em></div>
         <span class="preview-icon-name">msg</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chat&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chat"></em></div>
         <span class="preview-icon-name">chat</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-question-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-question-alt"></em></div>
         <span class="preview-icon-name">question-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-question&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-question"></em></div>
         <span class="preview-icon-name">question</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-msg-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-msg-circle-fill"></em></div>
         <span class="preview-icon-name">msg-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chat-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chat-circle-fill"></em></div>
         <span class="preview-icon-name">chat-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-msg-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-msg-fill"></em></div>
         <span class="preview-icon-name">msg-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chat-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chat-fill"></em></div>
         <span class="preview-icon-name">chat-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-clip-h&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-clip-h"></em></div>
         <span class="preview-icon-name">clip-h</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-clip-v&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-clip-v"></em></div>
         <span class="preview-icon-name">clip-v</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-clip&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-clip"></em></div>
         <span class="preview-icon-name">clip</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-link-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-link-alt"></em></div>
         <span class="preview-icon-name">link-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-unlink&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-unlink"></em></div>
         <span class="preview-icon-name">unlink</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-unlink-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-unlink-alt"></em></div>
         <span class="preview-icon-name">unlink-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-link-h&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-link-h"></em></div>
         <span class="preview-icon-name">link-h</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-link-v&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-link-v"></em></div>
         <span class="preview-icon-name">link-v</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-link&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-link"></em></div>
         <span class="preview-icon-name">link</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-clipboard&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-clipboard"></em></div>
         <span class="preview-icon-name">clipboard</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-clipboad-check&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-clipboad-check"></em></div>
         <span class="preview-icon-name">clipboad-check</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-clipboard-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-clipboard-fill"></em></div>
         <span class="preview-icon-name">clipboard-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-clipboad-check-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-clipboad-check-fill"></em></div>
         <span class="preview-icon-name">clipboad-check-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-clock&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-clock"></em></div>
         <span class="preview-icon-name">clock</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-clock-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-clock-fill"></em></div>
         <span class="preview-icon-name">clock-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cloud&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cloud"></em></div>
         <span class="preview-icon-name">cloud</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-upload-cloud&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-upload-cloud"></em></div>
         <span class="preview-icon-name">upload-cloud</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-download-cloud&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-download-cloud"></em></div>
         <span class="preview-icon-name">download-cloud</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cloud-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cloud-fill"></em></div>
         <span class="preview-icon-name">cloud-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-contact&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-contact"></em></div>
         <span class="preview-icon-name">contact</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-contact-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-contact-fill"></em></div>
         <span class="preview-icon-name">contact-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-coffee&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-coffee"></em></div>
         <span class="preview-icon-name">coffee</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-coffee-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-coffee-fill"></em></div>
         <span class="preview-icon-name">coffee-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-box-view&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-box-view"></em></div>
         <span class="preview-icon-name">box-view</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-col-view&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-col-view"></em></div>
         <span class="preview-icon-name">col-view</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sidebar&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sidebar"></em></div>
         <span class="preview-icon-name">sidebar</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-layout&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-layout"></em></div>
         <span class="preview-icon-name">layout</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-table-view&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-table-view"></em></div>
         <span class="preview-icon-name">table-view</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-layout2&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-layout2"></em></div>
         <span class="preview-icon-name">layout2</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-row-view&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-row-view"></em></div>
         <span class="preview-icon-name">row-view</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-dot-box&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-dot-box"></em></div>
         <span class="preview-icon-name">dot-box</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-layout-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-layout-fill"></em></div>
         <span class="preview-icon-name">layout-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-box-view-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-box-view-fill"></em></div>
         <span class="preview-icon-name">box-view-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sidebar-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sidebar-fill"></em></div>
         <span class="preview-icon-name">sidebar-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-table-view-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-table-view-fill"></em></div>
         <span class="preview-icon-name">table-view-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-dot-box-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-dot-box-fill"></em></div>
         <span class="preview-icon-name">dot-box-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-template&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-template"></em></div>
         <span class="preview-icon-name">template</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-browser&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-browser"></em></div>
         <span class="preview-icon-name">browser</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-toolbar&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-toolbar"></em></div>
         <span class="preview-icon-name">toolbar</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-browser-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-browser-fill"></em></div>
         <span class="preview-icon-name">browser-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-toolbar-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-toolbar-fill"></em></div>
         <span class="preview-icon-name">toolbar-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-template-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-template-fill"></em></div>
         <span class="preview-icon-name">template-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-box&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-box"></em></div>
         <span class="preview-icon-name">box</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-package&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-package"></em></div>
         <span class="preview-icon-name">package</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-layer&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-layer"></em></div>
         <span class="preview-icon-name">layer</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-layers&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-layers"></em></div>
         <span class="preview-icon-name">layers</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-panel&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-panel"></em></div>
         <span class="preview-icon-name">panel</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-server&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-server"></em></div>
         <span class="preview-icon-name">server</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-layer-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-layer-fill"></em></div>
         <span class="preview-icon-name">layer-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-layers-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-layers-fill"></em></div>
         <span class="preview-icon-name">layers-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-package-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-package-fill"></em></div>
         <span class="preview-icon-name">package-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-panel-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-panel-fill"></em></div>
         <span class="preview-icon-name">panel-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-server-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-server-fill"></em></div>
         <span class="preview-icon-name">server-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-color-palette&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-color-palette"></em></div>
         <span class="preview-icon-name">color-palette</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-color-palette-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-color-palette-fill"></em></div>
         <span class="preview-icon-name">color-palette-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-copy&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-copy"></em></div>
         <span class="preview-icon-name">copy</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-copy-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-copy-fill"></em></div>
         <span class="preview-icon-name">copy-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-crop-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-crop-alt"></em></div>
         <span class="preview-icon-name">crop-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-crop&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-crop"></em></div>
         <span class="preview-icon-name">crop</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-target&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-target"></em></div>
         <span class="preview-icon-name">target</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-crosshair&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-crosshair"></em></div>
         <span class="preview-icon-name">crosshair</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-crosshair-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-crosshair-fill"></em></div>
         <span class="preview-icon-name">crosshair-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-db-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-db-fill"></em></div>
         <span class="preview-icon-name">db-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-db&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-db"></em></div>
         <span class="preview-icon-name">db</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-hard-drive&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-hard-drive"></em></div>
         <span class="preview-icon-name">hard-drive</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cpu&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cpu"></em></div>
         <span class="preview-icon-name">cpu</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-disk&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-disk"></em></div>
         <span class="preview-icon-name">disk</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pen&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pen"></em></div>
         <span class="preview-icon-name">pen</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-edit-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-edit-alt"></em></div>
         <span class="preview-icon-name">edit-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pen-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pen-fill"></em></div>
         <span class="preview-icon-name">pen-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-edit-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-edit-alt-fill"></em></div>
         <span class="preview-icon-name">edit-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pen-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pen-alt-fill"></em></div>
         <span class="preview-icon-name">pen-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-edit-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-edit-fill"></em></div>
         <span class="preview-icon-name">edit-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-edit&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-edit"></em></div>
         <span class="preview-icon-name">edit</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-external-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-external-alt"></em></div>
         <span class="preview-icon-name">external-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-external&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-external"></em></div>
         <span class="preview-icon-name">external</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-eye-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-eye-alt"></em></div>
         <span class="preview-icon-name">eye-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-eye-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-eye-alt-fill"></em></div>
         <span class="preview-icon-name">eye-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-eye&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-eye"></em></div>
         <span class="preview-icon-name">eye</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-eye-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-eye-fill"></em></div>
         <span class="preview-icon-name">eye-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-eye-off&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-eye-off"></em></div>
         <span class="preview-icon-name">eye-off</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-eye-off-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-eye-off-fill"></em></div>
         <span class="preview-icon-name">eye-off-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file"></em></div>
         <span class="preview-icon-name">file</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-minus&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-minus"></em></div>
         <span class="preview-icon-name">file-minus</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-plus&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-plus"></em></div>
         <span class="preview-icon-name">file-plus</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-remove&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-remove"></em></div>
         <span class="preview-icon-name">file-remove</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-check&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-check"></em></div>
         <span class="preview-icon-name">file-check</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-code&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-code"></em></div>
         <span class="preview-icon-name">file-code</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-docs&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-docs"></em></div>
         <span class="preview-icon-name">file-docs</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-img&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-img"></em></div>
         <span class="preview-icon-name">file-img</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-doc&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-doc"></em></div>
         <span class="preview-icon-name">file-doc</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-pdf&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-pdf"></em></div>
         <span class="preview-icon-name">file-pdf</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-xls&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-xls"></em></div>
         <span class="preview-icon-name">file-xls</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-zip&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-zip"></em></div>
         <span class="preview-icon-name">file-zip</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-download&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-download"></em></div>
         <span class="preview-icon-name">file-download</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-text&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-text"></em></div>
         <span class="preview-icon-name">file-text</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-files&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-files"></em></div>
         <span class="preview-icon-name">files</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-fill"></em></div>
         <span class="preview-icon-name">file-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-minus-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-minus-fill"></em></div>
         <span class="preview-icon-name">file-minus-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-plus-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-plus-fill"></em></div>
         <span class="preview-icon-name">file-plus-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-remove-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-remove-fill"></em></div>
         <span class="preview-icon-name">file-remove-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-check-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-check-fill"></em></div>
         <span class="preview-icon-name">file-check-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-file-text-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-file-text-fill"></em></div>
         <span class="preview-icon-name">file-text-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-files-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-files-fill"></em></div>
         <span class="preview-icon-name">files-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-folder&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-folder"></em></div>
         <span class="preview-icon-name">folder</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-folder-minus&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-folder-minus"></em></div>
         <span class="preview-icon-name">folder-minus</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-folder-plus&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-folder-plus"></em></div>
         <span class="preview-icon-name">folder-plus</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-folder-remove&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-folder-remove"></em></div>
         <span class="preview-icon-name">folder-remove</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-folder-check&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-folder-check"></em></div>
         <span class="preview-icon-name">folder-check</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-folder-list&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-folder-list"></em></div>
         <span class="preview-icon-name">folder-list</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-folders&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-folders"></em></div>
         <span class="preview-icon-name">folders</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-folder-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-folder-fill"></em></div>
         <span class="preview-icon-name">folder-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-folders-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-folders-fill"></em></div>
         <span class="preview-icon-name">folders-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-filter-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-filter-alt"></em></div>
         <span class="preview-icon-name">filter-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sort-line&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sort-line"></em></div>
         <span class="preview-icon-name">sort-line</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-filter-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-filter-fill"></em></div>
         <span class="preview-icon-name">filter-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-filter&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-filter"></em></div>
         <span class="preview-icon-name">filter</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-flag&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-flag"></em></div>
         <span class="preview-icon-name">flag</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-flag-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-flag-fill"></em></div>
         <span class="preview-icon-name">flag-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-notify&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-notify"></em></div>
         <span class="preview-icon-name">notify</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-dashboard&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-dashboard"></em></div>
         <span class="preview-icon-name">dashboard</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-dashboard-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-dashboard-fill"></em></div>
         <span class="preview-icon-name">dashboard-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-sq&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-sq"></em></div>
         <span class="preview-icon-name">grid-sq</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid"></em></div>
         <span class="preview-icon-name">grid</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-c"></em></div>
         <span class="preview-icon-name">grid-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-alt"></em></div>
         <span class="preview-icon-name">grid-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-plus&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-plus"></em></div>
         <span class="preview-icon-name">grid-plus</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-add-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-add-c"></em></div>
         <span class="preview-icon-name">grid-add-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-fill"></em></div>
         <span class="preview-icon-name">grid-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-fill-c"></em></div>
         <span class="preview-icon-name">grid-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-alt-fill"></em></div>
         <span class="preview-icon-name">grid-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-plus-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-plus-fill"></em></div>
         <span class="preview-icon-name">grid-plus-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-add-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-add-fill-c"></em></div>
         <span class="preview-icon-name">grid-add-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-box-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-box-alt-fill"></em></div>
         <span class="preview-icon-name">grid-box-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-box-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-box-alt"></em></div>
         <span class="preview-icon-name">grid-box-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-box&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-box"></em></div>
         <span class="preview-icon-name">grid-box</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-box-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-box-fill"></em></div>
         <span class="preview-icon-name">grid-box-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-grid-line&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-grid-line"></em></div>
         <span class="preview-icon-name">grid-line</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-menu-alt-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-menu-alt-left"></em></div>
         <span class="preview-icon-name">menu-alt-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-menu-alt-r&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-menu-alt-r"></em></div>
         <span class="preview-icon-name">menu-alt-r</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-menu-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-menu-alt"></em></div>
         <span class="preview-icon-name">menu-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-menu-center&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-menu-center"></em></div>
         <span class="preview-icon-name">menu-center</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-menu-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-menu-left"></em></div>
         <span class="preview-icon-name">menu-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-menu-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-menu-right"></em></div>
         <span class="preview-icon-name">menu-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-menu&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-menu"></em></div>
         <span class="preview-icon-name">menu</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-trend-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-trend-up"></em></div>
         <span class="preview-icon-name">trend-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-trend-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-trend-down"></em></div>
         <span class="preview-icon-name">trend-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-line-chart-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-line-chart-down"></em></div>
         <span class="preview-icon-name">line-chart-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-line-chart-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-line-chart-up"></em></div>
         <span class="preview-icon-name">line-chart-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-line-chart&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-line-chart"></em></div>
         <span class="preview-icon-name">line-chart</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bar-chart&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bar-chart"></em></div>
         <span class="preview-icon-name">bar-chart</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bar-chart-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bar-chart-alt"></em></div>
         <span class="preview-icon-name">bar-chart-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chart-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chart-up"></em></div>
         <span class="preview-icon-name">chart-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-chart-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-chart-down"></em></div>
         <span class="preview-icon-name">chart-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-growth&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-growth"></em></div>
         <span class="preview-icon-name">growth</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-growth-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-growth-fill"></em></div>
         <span class="preview-icon-name">growth-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bar-chart-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bar-chart-fill"></em></div>
         <span class="preview-icon-name">bar-chart-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bar-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bar-c"></em></div>
         <span class="preview-icon-name">bar-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bar-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bar-fill-c"></em></div>
         <span class="preview-icon-name">bar-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pie&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pie"></em></div>
         <span class="preview-icon-name">pie</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pie-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pie-alt"></em></div>
         <span class="preview-icon-name">pie-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pie-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pie-fill"></em></div>
         <span class="preview-icon-name">pie-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-activity&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-activity"></em></div>
         <span class="preview-icon-name">activity</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-activity-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-activity-alt"></em></div>
         <span class="preview-icon-name">activity-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-activity-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-activity-round"></em></div>
         <span class="preview-icon-name">activity-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-activity-round-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-activity-round-fill"></em></div>
         <span class="preview-icon-name">activity-round-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-meter&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-meter"></em></div>
         <span class="preview-icon-name">meter</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-speed&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-speed"></em></div>
         <span class="preview-icon-name">speed</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-happy&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-happy"></em></div>
         <span class="preview-icon-name">happy</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sad&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sad"></em></div>
         <span class="preview-icon-name">sad</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-meh&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-meh"></em></div>
         <span class="preview-icon-name">meh</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-happyf-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-happyf-fill"></em></div>
         <span class="preview-icon-name">happyf-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sad-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sad-fill"></em></div>
         <span class="preview-icon-name">sad-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-meh-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-meh-fill"></em></div>
         <span class="preview-icon-name">meh-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-home&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-home"></em></div>
         <span class="preview-icon-name">home</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-home-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-home-alt"></em></div>
         <span class="preview-icon-name">home-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-home-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-home-fill"></em></div>
         <span class="preview-icon-name">home-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-img&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-img"></em></div>
         <span class="preview-icon-name">img</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-img-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-img-fill"></em></div>
         <span class="preview-icon-name">img-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-inbox&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-inbox"></em></div>
         <span class="preview-icon-name">inbox</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-inbox-in&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-inbox-in"></em></div>
         <span class="preview-icon-name">inbox-in</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-inbox-out&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-inbox-out"></em></div>
         <span class="preview-icon-name">inbox-out</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-inbox-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-inbox-fill"></em></div>
         <span class="preview-icon-name">inbox-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-inbox-in-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-inbox-in-fill"></em></div>
         <span class="preview-icon-name">inbox-in-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-inbox-out-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-inbox-out-fill"></em></div>
         <span class="preview-icon-name">inbox-out-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-link-group&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-link-group"></em></div>
         <span class="preview-icon-name">link-group</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-lock&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-lock"></em></div>
         <span class="preview-icon-name">lock</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-lock-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-lock-alt"></em></div>
         <span class="preview-icon-name">lock-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-lock-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-lock-fill"></em></div>
         <span class="preview-icon-name">lock-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-lock-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-lock-alt-fill"></em></div>
         <span class="preview-icon-name">lock-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-unlock&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-unlock"></em></div>
         <span class="preview-icon-name">unlock</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-unlock-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-unlock-fill"></em></div>
         <span class="preview-icon-name">unlock-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-mail&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-mail"></em></div>
         <span class="preview-icon-name">mail</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-emails&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-emails"></em></div>
         <span class="preview-icon-name">emails</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-mail-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-mail-fill"></em></div>
         <span class="preview-icon-name">mail-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-emails-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-emails-fill"></em></div>
         <span class="preview-icon-name">emails-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-map-pin&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-map-pin"></em></div>
         <span class="preview-icon-name">map-pin</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-location&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-location"></em></div>
         <span class="preview-icon-name">location</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-map&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-map"></em></div>
         <span class="preview-icon-name">map</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-map-pin-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-map-pin-fill"></em></div>
         <span class="preview-icon-name">map-pin-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-list&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-list"></em></div>
         <span class="preview-icon-name">list</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-list-ol&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-list-ol"></em></div>
         <span class="preview-icon-name">list-ol</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-align-center&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-align-center"></em></div>
         <span class="preview-icon-name">align-center</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-align-justify&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-align-justify"></em></div>
         <span class="preview-icon-name">align-justify</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-align-left&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-align-left"></em></div>
         <span class="preview-icon-name">align-left</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-align-right&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-align-right"></em></div>
         <span class="preview-icon-name">align-right</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-list-check&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-list-check"></em></div>
         <span class="preview-icon-name">list-check</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-list-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-list-round"></em></div>
         <span class="preview-icon-name">list-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-card-view&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-card-view"></em></div>
         <span class="preview-icon-name">card-view</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-list-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-list-fill"></em></div>
         <span class="preview-icon-name">list-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-save&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-save"></em></div>
         <span class="preview-icon-name">save</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-save-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-save-fill"></em></div>
         <span class="preview-icon-name">save-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-move&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-move"></em></div>
         <span class="preview-icon-name">move</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-scissor&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-scissor"></em></div>
         <span class="preview-icon-name">scissor</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-text&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-text"></em></div>
         <span class="preview-icon-name">text</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-text-a&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-text-a"></em></div>
         <span class="preview-icon-name">text-a</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bold&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bold"></em></div>
         <span class="preview-icon-name">bold</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-italic&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-italic"></em></div>
         <span class="preview-icon-name">italic</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-underline&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-underline"></em></div>
         <span class="preview-icon-name">underline</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-percent&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-percent"></em></div>
         <span class="preview-icon-name">percent</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-at&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-at"></em></div>
         <span class="preview-icon-name">at</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-hash&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-hash"></em></div>
         <span class="preview-icon-name">hash</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-code&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-code"></em></div>
         <span class="preview-icon-name">code</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-code-download&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-code-download"></em></div>
         <span class="preview-icon-name">code-download</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-terminal&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-terminal"></em></div>
         <span class="preview-icon-name">terminal</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cmd&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cmd"></em></div>
         <span class="preview-icon-name">cmd</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sun&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sun"></em></div>
         <span class="preview-icon-name">sun</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sun-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sun-fill"></em></div>
         <span class="preview-icon-name">sun-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-moon-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-moon-fill"></em></div>
         <span class="preview-icon-name">moon-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-moon&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-moon"></em></div>
         <span class="preview-icon-name">moon</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-light&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-light"></em></div>
         <span class="preview-icon-name">light</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-light-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-light-fill"></em></div>
         <span class="preview-icon-name">light-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-more-v&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-more-v"></em></div>
         <span class="preview-icon-name">more-v</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-more-h&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-more-h"></em></div>
         <span class="preview-icon-name">more-h</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-more-h-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-more-h-alt"></em></div>
         <span class="preview-icon-name">more-h-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-more-v-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-more-v-alt"></em></div>
         <span class="preview-icon-name">more-v-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-music&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-music"></em></div>
         <span class="preview-icon-name">music</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-movie&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-movie"></em></div>
         <span class="preview-icon-name">movie</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-offer&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-offer"></em></div>
         <span class="preview-icon-name">offer</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-offer-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-offer-fill"></em></div>
         <span class="preview-icon-name">offer-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-opt-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-opt-alt"></em></div>
         <span class="preview-icon-name">opt-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-opt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-opt"></em></div>
         <span class="preview-icon-name">opt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-opt-dot-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-opt-dot-alt"></em></div>
         <span class="preview-icon-name">opt-dot-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-opt-dot&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-opt-dot"></em></div>
         <span class="preview-icon-name">opt-dot</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-opt-dot-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-opt-dot-fill"></em></div>
         <span class="preview-icon-name">opt-dot-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-opt-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-opt-alt-fill"></em></div>
         <span class="preview-icon-name">opt-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-alt"></em></div>
         <span class="preview-icon-name">user-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-alt-fill"></em></div>
         <span class="preview-icon-name">user-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user"></em></div>
         <span class="preview-icon-name">user</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-users&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-users"></em></div>
         <span class="preview-icon-name">users</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-add&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-add"></em></div>
         <span class="preview-icon-name">user-add</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-remove&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-remove"></em></div>
         <span class="preview-icon-name">user-remove</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-check&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-check"></em></div>
         <span class="preview-icon-name">user-check</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-cross&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-cross"></em></div>
         <span class="preview-icon-name">user-cross</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-account-setting&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-account-setting"></em></div>
         <span class="preview-icon-name">account-setting</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-account-setting-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-account-setting-alt"></em></div>
         <span class="preview-icon-name">account-setting-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-list&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-list"></em></div>
         <span class="preview-icon-name">user-list</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-fill"></em></div>
         <span class="preview-icon-name">user-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-users-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-users-fill"></em></div>
         <span class="preview-icon-name">users-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-add-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-add-fill"></em></div>
         <span class="preview-icon-name">user-add-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-remove-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-remove-fill"></em></div>
         <span class="preview-icon-name">user-remove-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-check-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-check-fill"></em></div>
         <span class="preview-icon-name">user-check-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-cross-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-cross-fill"></em></div>
         <span class="preview-icon-name">user-cross-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-account-setting-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-account-setting-fill"></em></div>
         <span class="preview-icon-name">account-setting-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-list-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-list-fill"></em></div>
         <span class="preview-icon-name">user-list-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-circle"></em></div>
         <span class="preview-icon-name">user-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-circle-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-circle-fill"></em></div>
         <span class="preview-icon-name">user-circle-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-c"></em></div>
         <span class="preview-icon-name">user-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-fill-c"></em></div>
         <span class="preview-icon-name">user-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-user-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-user-round"></em></div>
         <span class="preview-icon-name">user-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-printer&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-printer"></em></div>
         <span class="preview-icon-name">printer</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-printer-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-printer-fill"></em></div>
         <span class="preview-icon-name">printer-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-laptop&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-laptop"></em></div>
         <span class="preview-icon-name">laptop</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-monitor&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-monitor"></em></div>
         <span class="preview-icon-name">monitor</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tablet&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tablet"></em></div>
         <span class="preview-icon-name">tablet</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-mobile&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-mobile"></em></div>
         <span class="preview-icon-name">mobile</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-undo&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-undo"></em></div>
         <span class="preview-icon-name">undo</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-redo&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-redo"></em></div>
         <span class="preview-icon-name">redo</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-reload-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-reload-alt"></em></div>
         <span class="preview-icon-name">reload-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-reload&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-reload"></em></div>
         <span class="preview-icon-name">reload</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-regen-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-regen-alt"></em></div>
         <span class="preview-icon-name">regen-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-regen&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-regen"></em></div>
         <span class="preview-icon-name">regen</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-invest&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-invest"></em></div>
         <span class="preview-icon-name">invest</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-histroy&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-histroy"></em></div>
         <span class="preview-icon-name">histroy</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-update&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-update"></em></div>
         <span class="preview-icon-name">update</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-repeat&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-repeat"></em></div>
         <span class="preview-icon-name">repeat</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-repeat-v&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-repeat-v"></em></div>
         <span class="preview-icon-name">repeat-v</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tranx&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tranx"></em></div>
         <span class="preview-icon-name">tranx</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-reply-all&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-reply-all"></em></div>
         <span class="preview-icon-name">reply-all</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-reply&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-reply"></em></div>
         <span class="preview-icon-name">reply</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-reply-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-reply-fill"></em></div>
         <span class="preview-icon-name">reply-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-reply-all-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-reply-all-fill"></em></div>
         <span class="preview-icon-name">reply-all-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-notes&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-notes"></em></div>
         <span class="preview-icon-name">notes</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-note-add&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-note-add"></em></div>
         <span class="preview-icon-name">note-add</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-notes-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-notes-alt"></em></div>
         <span class="preview-icon-name">notes-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-article&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-article"></em></div>
         <span class="preview-icon-name">article</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-text-rich&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-text-rich"></em></div>
         <span class="preview-icon-name">text-rich</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-todo&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-todo"></em></div>
         <span class="preview-icon-name">todo</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-report-profit&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-report-profit"></em></div>
         <span class="preview-icon-name">report-profit</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-reports-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-reports-alt"></em></div>
         <span class="preview-icon-name">reports-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-reports&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-reports"></em></div>
         <span class="preview-icon-name">reports</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-task&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-task"></em></div>
         <span class="preview-icon-name">task</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-note-add-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-note-add-c"></em></div>
         <span class="preview-icon-name">note-add-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-task-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-task-c"></em></div>
         <span class="preview-icon-name">task-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-todo-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-todo-fill"></em></div>
         <span class="preview-icon-name">todo-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-note-add-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-note-add-fill-c"></em></div>
         <span class="preview-icon-name">note-add-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-task-fill-c&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-task-fill-c"></em></div>
         <span class="preview-icon-name">task-fill-c</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-scan-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-scan-fill"></em></div>
         <span class="preview-icon-name">scan-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-scan&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-scan"></em></div>
         <span class="preview-icon-name">scan</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-qr&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-qr"></em></div>
         <span class="preview-icon-name">qr</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-money&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-money"></em></div>
         <span class="preview-icon-name">money</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-coins&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-coins"></em></div>
         <span class="preview-icon-name">coins</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-coin&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-coin"></em></div>
         <span class="preview-icon-name">coin</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-coin-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-coin-alt"></em></div>
         <span class="preview-icon-name">coin-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-coin-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-coin-alt-fill"></em></div>
         <span class="preview-icon-name">coin-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-setting-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-setting-alt-fill"></em></div>
         <span class="preview-icon-name">setting-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-setting-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-setting-alt"></em></div>
         <span class="preview-icon-name">setting-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-setting-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-setting-fill"></em></div>
         <span class="preview-icon-name">setting-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-setting&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-setting"></em></div>
         <span class="preview-icon-name">setting</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-share-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-share-alt"></em></div>
         <span class="preview-icon-name">share-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-share-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-share-fill"></em></div>
         <span class="preview-icon-name">share-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-share&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-share"></em></div>
         <span class="preview-icon-name">share</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-network&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-network"></em></div>
         <span class="preview-icon-name">network</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-rss&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-rss"></em></div>
         <span class="preview-icon-name">rss</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-shield&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-shield"></em></div>
         <span class="preview-icon-name">shield</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-shield-star&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-shield-star"></em></div>
         <span class="preview-icon-name">shield-star</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-shield-check&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-shield-check"></em></div>
         <span class="preview-icon-name">shield-check</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-shield-alert&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-shield-alert"></em></div>
         <span class="preview-icon-name">shield-alert</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-shield-off&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-shield-off"></em></div>
         <span class="preview-icon-name">shield-off</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-security&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-security"></em></div>
         <span class="preview-icon-name">security</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-policy&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-policy"></em></div>
         <span class="preview-icon-name">policy</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-shield-alert-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-shield-alert-fill"></em></div>
         <span class="preview-icon-name">shield-alert-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-shield-check-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-shield-check-fill"></em></div>
         <span class="preview-icon-name">shield-check-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-shield-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-shield-fill"></em></div>
         <span class="preview-icon-name">shield-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-shield-half&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-shield-half"></em></div>
         <span class="preview-icon-name">shield-half</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-shield-star-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-shield-star-fill"></em></div>
         <span class="preview-icon-name">shield-star-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-policy-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-policy-fill"></em></div>
         <span class="preview-icon-name">policy-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-spark&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-spark"></em></div>
         <span class="preview-icon-name">spark</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-spark-off&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-spark-off"></em></div>
         <span class="preview-icon-name">spark-off</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-spark-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-spark-fill"></em></div>
         <span class="preview-icon-name">spark-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-spark-off-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-spark-off-fill"></em></div>
         <span class="preview-icon-name">spark-off-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-wallet&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-wallet"></em></div>
         <span class="preview-icon-name">wallet</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-wallet-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-wallet-alt"></em></div>
         <span class="preview-icon-name">wallet-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-wallet-in&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-wallet-in"></em></div>
         <span class="preview-icon-name">wallet-in</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-wallet-out&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-wallet-out"></em></div>
         <span class="preview-icon-name">wallet-out</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-wallet-saving&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-wallet-saving"></em></div>
         <span class="preview-icon-name">wallet-saving</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-wallet-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-wallet-fill"></em></div>
         <span class="preview-icon-name">wallet-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-star&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-star"></em></div>
         <span class="preview-icon-name">star</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-star-half&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-star-half"></em></div>
         <span class="preview-icon-name">star-half</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-star-half-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-star-half-fill"></em></div>
         <span class="preview-icon-name">star-half-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-star-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-star-fill"></em></div>
         <span class="preview-icon-name">star-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-star-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-star-round"></em></div>
         <span class="preview-icon-name">star-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-heart&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-heart"></em></div>
         <span class="preview-icon-name">heart</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-heart-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-heart-fill"></em></div>
         <span class="preview-icon-name">heart-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-swap-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-swap-alt-fill"></em></div>
         <span class="preview-icon-name">swap-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-swap-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-swap-alt"></em></div>
         <span class="preview-icon-name">swap-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-thumbs-down&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-thumbs-down"></em></div>
         <span class="preview-icon-name">thumbs-down</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-thumbs-up&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-thumbs-up"></em></div>
         <span class="preview-icon-name">thumbs-up</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tag&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tag"></em></div>
         <span class="preview-icon-name">tag</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tag-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tag-alt"></em></div>
         <span class="preview-icon-name">tag-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tags&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tags"></em></div>
         <span class="preview-icon-name">tags</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tag-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tag-fill"></em></div>
         <span class="preview-icon-name">tag-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tag-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tag-alt-fill"></em></div>
         <span class="preview-icon-name">tag-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tags-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tags-fill"></em></div>
         <span class="preview-icon-name">tags-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bookmark&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bookmark"></em></div>
         <span class="preview-icon-name">bookmark</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bookmark-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bookmark-fill"></em></div>
         <span class="preview-icon-name">bookmark-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-label&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-label"></em></div>
         <span class="preview-icon-name">label</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-label-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-label-fill"></em></div>
         <span class="preview-icon-name">label-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-piority&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-piority"></em></div>
         <span class="preview-icon-name">piority</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-piority-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-piority-fill"></em></div>
         <span class="preview-icon-name">piority-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-label-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-label-alt"></em></div>
         <span class="preview-icon-name">label-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-label-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-label-alt-fill"></em></div>
         <span class="preview-icon-name">label-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-ticket-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-ticket-alt"></em></div>
         <span class="preview-icon-name">ticket-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-ticket&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-ticket"></em></div>
         <span class="preview-icon-name">ticket</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-ticket-minus&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-ticket-minus"></em></div>
         <span class="preview-icon-name">ticket-minus</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-ticket-plus&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-ticket-plus"></em></div>
         <span class="preview-icon-name">ticket-plus</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-ticket-alt-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-ticket-alt-fill"></em></div>
         <span class="preview-icon-name">ticket-alt-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-ticket-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-ticket-fill"></em></div>
         <span class="preview-icon-name">ticket-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-ticket-minus-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-ticket-minus-fill"></em></div>
         <span class="preview-icon-name">ticket-minus-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-ticket-plus-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-ticket-plus-fill"></em></div>
         <span class="preview-icon-name">ticket-plus-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-toggle-off&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-toggle-off"></em></div>
         <span class="preview-icon-name">toggle-off</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-toggle-on&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-toggle-on"></em></div>
         <span class="preview-icon-name">toggle-on</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-trash-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-trash-alt"></em></div>
         <span class="preview-icon-name">trash-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-trash-empty&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-trash-empty"></em></div>
         <span class="preview-icon-name">trash-empty</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-trash&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-trash"></em></div>
         <span class="preview-icon-name">trash</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-trash-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-trash-fill"></em></div>
         <span class="preview-icon-name">trash-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-trash-empty-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-trash-empty-fill"></em></div>
         <span class="preview-icon-name">trash-empty-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-delete-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-delete-fill"></em></div>
         <span class="preview-icon-name">delete-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-delete&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-delete"></em></div>
         <span class="preview-icon-name">delete</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-alarm-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-alarm-alt"></em></div>
         <span class="preview-icon-name">alarm-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-alarm&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-alarm"></em></div>
         <span class="preview-icon-name">alarm</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bugs&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bugs"></em></div>
         <span class="preview-icon-name">bugs</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-building&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-building"></em></div>
         <span class="preview-icon-name">building</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-building-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-building-fill"></em></div>
         <span class="preview-icon-name">building-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-headphone&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-headphone"></em></div>
         <span class="preview-icon-name">headphone</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-headphone-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-headphone-fill"></em></div>
         <span class="preview-icon-name">headphone-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-aperture&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-aperture"></em></div>
         <span class="preview-icon-name">aperture</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-help-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-help-alt"></em></div>
         <span class="preview-icon-name">help-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-award&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-award"></em></div>
         <span class="preview-icon-name">award</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-briefcase&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-briefcase"></em></div>
         <span class="preview-icon-name">briefcase</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-gift&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-gift"></em></div>
         <span class="preview-icon-name">gift</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-globe&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-globe"></em></div>
         <span class="preview-icon-name">globe</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-umbrela&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-umbrela"></em></div>
         <span class="preview-icon-name">umbrela</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-truck&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-truck"></em></div>
         <span class="preview-icon-name">truck</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-usd&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-usd"></em></div>
         <span class="preview-icon-name">sign-usd</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-dollar&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-dollar"></em></div>
         <span class="preview-icon-name">sign-dollar</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-mxn&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-mxn"></em></div>
         <span class="preview-icon-name">sign-mxn</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-sgd&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-sgd"></em></div>
         <span class="preview-icon-name">sign-sgd</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-euro&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-euro"></em></div>
         <span class="preview-icon-name">sign-euro</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-eur&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-eur"></em></div>
         <span class="preview-icon-name">sign-eur</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-gbp&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-gbp"></em></div>
         <span class="preview-icon-name">sign-gbp</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-pound&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-pound"></em></div>
         <span class="preview-icon-name">sign-pound</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-thb&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-thb"></em></div>
         <span class="preview-icon-name">sign-thb</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-inr&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-inr"></em></div>
         <span class="preview-icon-name">sign-inr</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-jpy&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-jpy"></em></div>
         <span class="preview-icon-name">sign-jpy</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-yen&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-yen"></em></div>
         <span class="preview-icon-name">sign-yen</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-cny&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-cny"></em></div>
         <span class="preview-icon-name">sign-cny</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-kobo&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-kobo"></em></div>
         <span class="preview-icon-name">sign-kobo</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-chf&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-chf"></em></div>
         <span class="preview-icon-name">sign-chf</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-vnd&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-vnd"></em></div>
         <span class="preview-icon-name">sign-vnd</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-php&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-php"></em></div>
         <span class="preview-icon-name">sign-php</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-brl&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-brl"></em></div>
         <span class="preview-icon-name">sign-brl</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-idr&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-idr"></em></div>
         <span class="preview-icon-name">sign-idr</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-czk&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-czk"></em></div>
         <span class="preview-icon-name">sign-czk</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-hkd&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-hkd"></em></div>
         <span class="preview-icon-name">sign-hkd</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-kr&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-kr"></em></div>
         <span class="preview-icon-name">sign-kr</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-dkk&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-dkk"></em></div>
         <span class="preview-icon-name">sign-dkk</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-nok&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-nok"></em></div>
         <span class="preview-icon-name">sign-nok</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-sek&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-sek"></em></div>
         <span class="preview-icon-name">sign-sek</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-rub&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-rub"></em></div>
         <span class="preview-icon-name">sign-rub</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-myr&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-myr"></em></div>
         <span class="preview-icon-name">sign-myr</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-pln&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-pln"></em></div>
         <span class="preview-icon-name">sign-pln</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-try&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-try"></em></div>
         <span class="preview-icon-name">sign-try</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-waves&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-waves"></em></div>
         <span class="preview-icon-name">sign-waves</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-waves&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-waves"></em></div>
         <span class="preview-icon-name">waves</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-trx&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-trx"></em></div>
         <span class="preview-icon-name">sign-trx</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tron&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tron"></em></div>
         <span class="preview-icon-name">tron</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-xem&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-xem"></em></div>
         <span class="preview-icon-name">sign-xem</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-nem&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-nem"></em></div>
         <span class="preview-icon-name">nem</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-mxr&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-mxr"></em></div>
         <span class="preview-icon-name">sign-mxr</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-monero&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-monero"></em></div>
         <span class="preview-icon-name">monero</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-usdc&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-usdc"></em></div>
         <span class="preview-icon-name">sign-usdc</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-steller&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-steller"></em></div>
         <span class="preview-icon-name">sign-steller</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-steem&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-steem"></em></div>
         <span class="preview-icon-name">sign-steem</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-usdt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-usdt"></em></div>
         <span class="preview-icon-name">sign-usdt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tether&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tether"></em></div>
         <span class="preview-icon-name">tether</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-btc&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-btc"></em></div>
         <span class="preview-icon-name">sign-btc</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bitcoin&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bitcoin"></em></div>
         <span class="preview-icon-name">bitcoin</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-bch&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-bch"></em></div>
         <span class="preview-icon-name">sign-bch</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bitcoin-cash&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bitcoin-cash"></em></div>
         <span class="preview-icon-name">bitcoin-cash</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-bnb&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-bnb"></em></div>
         <span class="preview-icon-name">sign-bnb</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-binance&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-binance"></em></div>
         <span class="preview-icon-name">binance</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-ada&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-ada"></em></div>
         <span class="preview-icon-name">sign-ada</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-zcash&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-zcash"></em></div>
         <span class="preview-icon-name">sign-zcash</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-eth&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-eth"></em></div>
         <span class="preview-icon-name">sign-eth</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-ethereum&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-ethereum"></em></div>
         <span class="preview-icon-name">ethereum</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-dash&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-dash"></em></div>
         <span class="preview-icon-name">sign-dash</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-dash&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-dash"></em></div>
         <span class="preview-icon-name">dash</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-xrp-old&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-xrp-old"></em></div>
         <span class="preview-icon-name">sign-xrp-old</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-ripple-old&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-ripple-old"></em></div>
         <span class="preview-icon-name">ripple-old</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-eos&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-eos"></em></div>
         <span class="preview-icon-name">sign-eos</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-eos&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-eos"></em></div>
         <span class="preview-icon-name">eos</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-sign-xrp&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-sign-xrp"></em></div>
         <span class="preview-icon-name">sign-xrp</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-ripple&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-ripple"></em></div>
         <span class="preview-icon-name">ripple</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-american-express&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-american-express"></em></div>
         <span class="preview-icon-name">american-express</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-jcb&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-jcb"></em></div>
         <span class="preview-icon-name">jcb</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-jcb&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-jcb"></em></div>
         <span class="preview-icon-name">cc-jcb</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-mc&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-mc"></em></div>
         <span class="preview-icon-name">mc</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-mc&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-mc"></em></div>
         <span class="preview-icon-name">cc-mc</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-discover&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-discover"></em></div>
         <span class="preview-icon-name">discover</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-discover&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-discover"></em></div>
         <span class="preview-icon-name">cc-discover</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-visa&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-visa"></em></div>
         <span class="preview-icon-name">visa</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-visa&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-visa"></em></div>
         <span class="preview-icon-name">cc-visa</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-paypal&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-paypal"></em></div>
         <span class="preview-icon-name">cc-paypal</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-cc-stripe&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-cc-stripe"></em></div>
         <span class="preview-icon-name">cc-stripe</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-amazon-pay&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-amazon-pay"></em></div>
         <span class="preview-icon-name">amazon-pay</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-amazon-pay-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-amazon-pay-fill"></em></div>
         <span class="preview-icon-name">amazon-pay-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-google-pay&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-google-pay"></em></div>
         <span class="preview-icon-name">google-pay</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-google-pay-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-google-pay-fill"></em></div>
         <span class="preview-icon-name">google-pay-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-apple-pay&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-apple-pay"></em></div>
         <span class="preview-icon-name">apple-pay</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-apple-pay-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-apple-pay-fill"></em></div>
         <span class="preview-icon-name">apple-pay-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-angular&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-angular"></em></div>
         <span class="preview-icon-name">angular</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-react&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-react"></em></div>
         <span class="preview-icon-name">react</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-laravel&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-laravel"></em></div>
         <span class="preview-icon-name">laravel</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-html5&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-html5"></em></div>
         <span class="preview-icon-name">html5</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-css3-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-css3-fill"></em></div>
         <span class="preview-icon-name">css3-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-css3&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-css3"></em></div>
         <span class="preview-icon-name">css3</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-js&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-js"></em></div>
         <span class="preview-icon-name">js</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-php&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-php"></em></div>
         <span class="preview-icon-name">php</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-python&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-python"></em></div>
         <span class="preview-icon-name">python</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-bootstrap&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-bootstrap"></em></div>
         <span class="preview-icon-name">bootstrap</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-ebay&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-ebay"></em></div>
         <span class="preview-icon-name">ebay</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-google-wallet&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-google-wallet"></em></div>
         <span class="preview-icon-name">google-wallet</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-google-drive&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-google-drive"></em></div>
         <span class="preview-icon-name">google-drive</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-google-play-store&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-google-play-store"></em></div>
         <span class="preview-icon-name">google-play-store</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-android&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-android"></em></div>
         <span class="preview-icon-name">android</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-blogger-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-blogger-fill"></em></div>
         <span class="preview-icon-name">blogger-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-blogger&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-blogger"></em></div>
         <span class="preview-icon-name">blogger</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-hangout&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-hangout"></em></div>
         <span class="preview-icon-name">hangout</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-apple-store&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-apple-store"></em></div>
         <span class="preview-icon-name">apple-store</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-apple-store-ios&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-apple-store-ios"></em></div>
         <span class="preview-icon-name">apple-store-ios</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-stripe&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-stripe"></em></div>
         <span class="preview-icon-name">stripe</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-apple&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-apple"></em></div>
         <span class="preview-icon-name">apple</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-microsoft&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-microsoft"></em></div>
         <span class="preview-icon-name">microsoft</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-windows&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-windows"></em></div>
         <span class="preview-icon-name">windows</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-amazon&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-amazon"></em></div>
         <span class="preview-icon-name">amazon</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-paypal-alt&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-paypal-alt"></em></div>
         <span class="preview-icon-name">paypal-alt</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-airbnb&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-airbnb"></em></div>
         <span class="preview-icon-name">airbnb</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-adobe&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-adobe"></em></div>
         <span class="preview-icon-name">adobe</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-mailchimp&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-mailchimp"></em></div>
         <span class="preview-icon-name">mailchimp</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-dropbox&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-dropbox"></em></div>
         <span class="preview-icon-name">dropbox</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-digital-ocean&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-digital-ocean"></em></div>
         <span class="preview-icon-name">digital-ocean</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-slack&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-slack"></em></div>
         <span class="preview-icon-name">slack</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-slack-hash&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-slack-hash"></em></div>
         <span class="preview-icon-name">slack-hash</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-stack-overflow&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-stack-overflow"></em></div>
         <span class="preview-icon-name">stack-overflow</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-soundcloud&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-soundcloud"></em></div>
         <span class="preview-icon-name">soundcloud</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-blackberry&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-blackberry"></em></div>
         <span class="preview-icon-name">blackberry</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-spotify&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-spotify"></em></div>
         <span class="preview-icon-name">spotify</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-kickstarter&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-kickstarter"></em></div>
         <span class="preview-icon-name">kickstarter</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-houzz&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-houzz"></em></div>
         <span class="preview-icon-name">houzz</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-vine&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-vine"></em></div>
         <span class="preview-icon-name">vine</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-yelp&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-yelp"></em></div>
         <span class="preview-icon-name">yelp</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-yoast&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-yoast"></em></div>
         <span class="preview-icon-name">yoast</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-envato&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-envato"></em></div>
         <span class="preview-icon-name">envato</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-wordpress&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-wordpress"></em></div>
         <span class="preview-icon-name">wordpress</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-wp&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-wp"></em></div>
         <span class="preview-icon-name">wp</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-wordpress-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-wordpress-fill"></em></div>
         <span class="preview-icon-name">wordpress-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-elementor&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-elementor"></em></div>
         <span class="preview-icon-name">elementor</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-joomla&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-joomla"></em></div>
         <span class="preview-icon-name">joomla</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-megento&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-megento"></em></div>
         <span class="preview-icon-name">megento</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-git&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-git"></em></div>
         <span class="preview-icon-name">git</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-github&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-github"></em></div>
         <span class="preview-icon-name">github</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-github-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-github-round"></em></div>
         <span class="preview-icon-name">github-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-github-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-github-circle"></em></div>
         <span class="preview-icon-name">github-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-dribbble&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-dribbble"></em></div>
         <span class="preview-icon-name">dribbble</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-dribbble-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-dribbble-round"></em></div>
         <span class="preview-icon-name">dribbble-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-behance&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-behance"></em></div>
         <span class="preview-icon-name">behance</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-behance-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-behance-fill"></em></div>
         <span class="preview-icon-name">behance-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-flickr&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-flickr"></em></div>
         <span class="preview-icon-name">flickr</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-flickr-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-flickr-round"></em></div>
         <span class="preview-icon-name">flickr-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-medium&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-medium"></em></div>
         <span class="preview-icon-name">medium</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-medium-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-medium-round"></em></div>
         <span class="preview-icon-name">medium-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-reddit&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-reddit"></em></div>
         <span class="preview-icon-name">reddit</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-reddit-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-reddit-round"></em></div>
         <span class="preview-icon-name">reddit-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-reddit-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-reddit-circle"></em></div>
         <span class="preview-icon-name">reddit-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-google&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-google"></em></div>
         <span class="preview-icon-name">google</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-facebook-f&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-facebook-f"></em></div>
         <span class="preview-icon-name">facebook-f</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-facebook-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-facebook-fill"></em></div>
         <span class="preview-icon-name">facebook-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-facebook-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-facebook-circle"></em></div>
         <span class="preview-icon-name">facebook-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-instagram&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-instagram"></em></div>
         <span class="preview-icon-name">instagram</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-instagram-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-instagram-round"></em></div>
         <span class="preview-icon-name">instagram-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-linkedin&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-linkedin"></em></div>
         <span class="preview-icon-name">linkedin</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-linkedin-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-linkedin-round"></em></div>
         <span class="preview-icon-name">linkedin-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-twitter&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-twitter"></em></div>
         <span class="preview-icon-name">twitter</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-twitter-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-twitter-round"></em></div>
         <span class="preview-icon-name">twitter-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pinterest&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pinterest"></em></div>
         <span class="preview-icon-name">pinterest</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pinterest-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pinterest-round"></em></div>
         <span class="preview-icon-name">pinterest-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-pinterest-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-pinterest-circle"></em></div>
         <span class="preview-icon-name">pinterest-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tumblr&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tumblr"></em></div>
         <span class="preview-icon-name">tumblr</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-tumblr-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-tumblr-round"></em></div>
         <span class="preview-icon-name">tumblr-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-skype&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-skype"></em></div>
         <span class="preview-icon-name">skype</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-viber&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-viber"></em></div>
         <span class="preview-icon-name">viber</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-whatsapp&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-whatsapp"></em></div>
         <span class="preview-icon-name">whatsapp</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-whatsapp-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-whatsapp-round"></em></div>
         <span class="preview-icon-name">whatsapp-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-snapchat&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-snapchat"></em></div>
         <span class="preview-icon-name">snapchat</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-snapchat-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-snapchat-fill"></em></div>
         <span class="preview-icon-name">snapchat-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-telegram&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-telegram"></em></div>
         <span class="preview-icon-name">telegram</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-telegram-circle&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-telegram-circle"></em></div>
         <span class="preview-icon-name">telegram-circle</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-youtube-line&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-youtube-line"></em></div>
         <span class="preview-icon-name">youtube-line</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-youtube&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-youtube"></em></div>
         <span class="preview-icon-name">youtube</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-youtube-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-youtube-fill"></em></div>
         <span class="preview-icon-name">youtube-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-youtube-round&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-youtube-round"></em></div>
         <span class="preview-icon-name">youtube-round</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-vimeo&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-vimeo"></em></div>
         <span class="preview-icon-name">vimeo</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
   <li class="preview-icon-item">
      <div class="preview-icon-box card">
         <button type="button" class="btn btn-icon btn-clipboard clipboard-init clipboard-text" data-clip-success="<em class=&quot;icon ni ni-copy-fill&quot;></em>" data-clip-text="<em class=&quot;icon ni ni-copy&quot;></em>" data-clipboard-text="<em class=&quot;icon ni ni-vimeo-fill&quot;></em>"><em class="icon ni ni-copy"></em></button>
         <div class="preview-icon-wrap"><em class="ni ni-vimeo-fill"></em></div>
         <span class="preview-icon-name">vimeo-fill</span><span class="clipboard-success-message text-primary">Copied</span>
      </div>
   </li>
</ul>
  </div>
</template>

<script>
export default {
   created() {
      this.getView()
   },
   methods: {
      getView() {
            this.$http.get('/ViewAccess/devteamdates')
            .then(() => {
            })
            .catch(() => {
            })
        },
   }
}
</script>

<style>

</style>